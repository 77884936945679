import type { ActionTree } from 'vuex';
import {
  FETCH_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
} from './action-types';
import {
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
} from './mutation-types';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import type {
  IntNotificationState,
} from './IntNotificationState';
import type {
  IntNotification, IntNotificationServiceResponse,
} from '@/common/interfaces/notifications/INotification';

import DateUtil from '@/common/util/DateUtil';

import NotificationService from '@/common/services/NotificationService';
import { DateTimeFormat } from '@/components/global/TDateTime/IDateTime';

export const actions: ActionTree<IntNotificationState, {}> = {
  /**
   * Dispatch the get request to fetch the user's notification
   */
  async [FETCH_NOTIFICATIONS] ({ commit }) {
    let _data: IntNotificationServiceResponse[] = [];
    try {
      const res = await NotificationService.getUserNotifications();
      if (res.status === 200) {
        _data = res.data.map(item => {
          if (item.properties !== null && Array.isArray(item.properties) && item.properties?.length === 4 && item.notificationType !== 'ROLE_CHANGED') {
            const [, dataspaceName, ...dsInfo] = item.properties;
            item.properties = {
              dataspaceName,
              ...JSON.parse(dsInfo.join(',')),
            };
          } else {
            item.properties = null;
          }
          return item;
        });
        commit(SET_NOTIFICATIONS, _data);
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'Error while loading notifications', { root: true });
      }
      return _data;
    } catch {
      commit(ADD_ERROR_NOTIFICATION, 'Error while loading notifications', { root: true });
      return 'error';
    }
  },

  /**
   * Calls the notifiation read request with
   * @param0 context
   * @param notificationId
   */
  async [SET_NOTIFICATION_READ] ({ commit }, notification: IntNotification) {
    const res = await NotificationService.setReadNotification(Number(notification.id));
    if (res.status === 200) {
      commit(UPDATE_NOTIFICATION, notification);
    }
  },
};
